<template>
  <div>
    <TTableAdvance
      :items="users"
      :fields="fields"
      store="product.purchase_suppliers"
      @click-clear-filter="clearFilter"
      resource="/purchase/suppliers"
      creatable
      enterable
      @click-create="showModalCreate = true"
    >
      <template #name="{ item }">
        <td>
          <TMessage :content="item.name" noTranslate />
        </td>
      </template>
      <template #email="{ item }">
        <td>
          <TMessage :content="item.email" noTranslate />
        </td>
      </template>
      <template #link="{ item }">
        <td>
          <TLink v-if="item.link" :href="item.link" :content="item.link" />
          <TMessage v-else :content="item.link" noTranslate />
        </td>
      </template>
      <template #address="{ item }">
        <td>
          <TMessage :content="item.address" noTranslate />
        </td>
      </template>
      <template #note="{ item }">
        <td>
          <TMessage :content="item.note" noTranslate />
        </td>
      </template>
      <template #created_at="{ item }">
        <td>
          <TMessageDateTime :content="item.created_at" small />
        </td>
      </template>
      <template #id-filter>
        <TInputText
          :value.sync="filter.id"
          @update:value="filterChange"
          placeholder="Id"
        />
      </template>
      <template #name-filter>
        <TInputText
          :value.sync="filter.name"
          @update:value="filterChange"
          placeholder="Supplier"
        />
      </template>
      <template #email-filter>
        <TInputText
          :value.sync="filter.email"
          @update:value="filterChange"
          placeholder="Email"
        />
      </template>
    </TTableAdvance>
    <AddSupplier :show.sync="showModalCreate" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AddSupplier from "./components/AddSupplier.vue";

export default {
  components: { AddSupplier },
  data() {
    return {
      fields: [
        { key: "_", _style: "width: 40px" },
        { key: "id", label: "Supplier Id", _style: "width: 100px" },
        { key: "name", label: "Name", _style: "min-width: 250px" },
        { key: "address", label: "Address", _style: "min-width: 250px" },
        { key: "email", label: "Email", _style: "min-width: 200px" },
        { key: "link", label: "Link", _style: "min-width: 200px" },
        { key: "note", label: "Note", _style: "min-width: 200px" },
      ],
      showModalCreate: false,
      filter: {},
    };
  },
  created() {
    this.$store.dispatch("product.purchase_suppliers.fetch.if-first-time");
  },
  computed: {
    ...mapGetters({
      users: "product.purchase_suppliers.list",
    }),
  },
  methods: {
    filterChange() {
      const filter = this.lodash.mapKeys(this.filter, function (value, key) {
        return `filter[${key}]`;
      });
      const filterFields = this.lodash.pickBy(filter);
      this.$store.dispatch(
        "product.purchase_suppliers.apply-query",
        filterFields
      );
    },
    clearFilter() {
      this.filter = {};
      this.filterChange();
    },
  },
};
</script>
