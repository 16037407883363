<template>
  <TModal
    title="Create Supplier"
    :show="show"
    @update:show="$emit('update:show', $event)"
  >
    <template #actions>
      <TButtonCreate
        :options="{ disabled: loading }"
        variant="outline"
        @click="create"
      />
    </template>
    <CRow>
      <CCol col="12">
        <TInputText
          class="mt-3"
          placeholder="Enter name"
          label="Name"
          :value.sync="data.name"
        >
          <template #prepend-content>
            <CIcon name="cilStore" />
          </template>
        </TInputText>
        <TInputEmail class="mt-3" label="Email" :value.sync="data.email" />
        <TInputText
          class="mt-3"
          placeholder="Enter address"
          label="Address"
          :value.sync="data.address"
        >
          <template #prepend-content>
            <CIcon name="cilMap" />
          </template>
        </TInputText>
        <TInputText
          class="mt-3"
          placeholder="Enter link"
          label="Link"
          :value.sync="data.link"
        >
          <template #prepend-content>
            <CIcon name="cilLink" />
          </template>
        </TInputText>
        <TInputText
          class="mt-3"
          placeholder="Enter note"
          label="Note"
          :value.sync="data.note"
        >
          <template #prepend-content>
            <CIcon name="cilMap" />
          </template>
        </TInputText>
      </CCol>
    </CRow>
  </TModal>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      data: {
        name: null,
        link: null,
        email: null,
        address: null,
        note: null,
      },
    };
  },
  methods: {
    create() {
      this.loading = true;
      const data = this.lodash.pickBy(this.data, this.lodash.identity);
      this.$store
        .dispatch("product.purchase_suppliers.create", data)
        .then((data) => {
          this.$router.push({ path: `/purchase/suppliers/${data.id}` });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
